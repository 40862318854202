/**
 * Generate permission string
 * @param module
 * @param permission
 * @returns {string}
 */
const prepare = (module, permission) => {
  return `${module}.${permission}`;
};

/**
 * Generate crud permission prop
 * @param module
 * @returns {{create: string, update: string, delete: string, browse: string}}
 */
export const generate = (module) => {
  return {
    browse: prepare(module, 'browse'),
    create: prepare(module, 'create'),
    update: prepare(module, 'update'),
    delete: prepare(module, 'delete')
  }
}

const general = {
  settings_access: 'settings.access',
  settings_update: 'settings.update',
  settings_general_settings_update: 'settings.general_settings_update',
  fake: 'fake',
  table_export: 'table.export',
  table_import: 'table.import',
  can_see_internal_comments: 'comments.can_see_internal_comments',

  can_see_history: 'can_see_history',

  can_update_all_comments: 'comments.edit_all_comments',
  can_delete_comments: 'comments.delete_comments',

  can_see_grid_settings: 'settings.can_see_grid_settings'
}


const buttons = {
  ...generate('buttons')
}

const crm_reports = {
  browse: 'crm_reports.browse',
  average_response_time: 'crm_reports.average_response_time',
  average_time_to_resolution: 'crm_reports.average_time_to_resolution',
  calls_resulting_in_tickets: 'crm_reports.calls_resulting_in_tickets',
  sla_penalties: 'crm_reports.sla_penalties',
  tickets_opened_to_tickets_closed: 'crm_reports.tickets_opened_to_tickets_closed',
  tickets_resulting_on_site_support: 'crm_reports.tickets_resulting_on_site_support',
  tickets_service_category: 'crm_reports.tickets_service_category',
  monthly_ticket_report: 'crm_reports.monthly_ticket_report',
  monthly_ticket_report_create: 'crm_reports.create_monthly_ticket_report',

}

const dwh_reports = {
  browse: 'dwh_reports.browse',
  call_volume_per_range_of_call_taker_answer_time: 'dwh_reports.call_volume_per_range_of_call_taker_answer_time',
  call_summary : 'dwh_reports.call_summary',
  call_per_hours : 'dwh_reports.call_per_hours',
  call_summary_detailed : 'dwh_reports.call_summary_detailed',
  average_call_duration : 'dwh_reports.average_call_duration',
  calls_by_agent: 'dwh_reports.calls_by_agent',
  agent_ring_time: 'dwh_reports.agent_ring_time',
  psap_ring_time: 'dwh_reports.psap_ring_time',
  daily_call_taker_call_volume_per_hour_of_they_day: 'dwh_reports.daily_call_taker_call_volume_per_hour_of_they_day',
  agent_call_summary: 'dwh_reports.agent_call_summary',
  call_log: 'dwh_reports.call_log',
  transfer_report: 'dwh_reports.transfer_report',
}


const groups = {
  ...generate('groups')
}
const users = {
  ...generate('users'),
  login_as_user: 'users.login_as_user',

}

const listViews = {
  ...generate('list-views')
}
const roles = {
  ...generate('roles')
}

const forms = {
  ...generate('forms'),
}
const forms_data = {
  ...generate('forms_data'),
}

export default {
  general,
  groups,
  users,
  roles,
  forms,
  forms_data,
  buttons,
  listViews,
  crm_reports,
  dwh_reports
}
